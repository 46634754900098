import React from "react"
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper kf5iz922pv-editor_css",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>Request a Demo</p>
            </span>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <p>
              Enable your recruiting team to execute the perfect recruiting
              process - learn how DeepHire works today.
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: "banner5-image",
    children: "https://deephire.s3.amazonaws.com/websiteAssets/formDemo.png",
  },
}
export const Feature00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>
                DeepHire Drives Higher Performance at Every Stage of the
                Recruiting Process
              </p>
            </span>
          </span>
        ),
      },
      {
        name: "content~kf5em3g0blo",
        className: "",
        children: (
          <span>
            <p>
              From Interviewing candidates to sharing them with clients, our
              recruiting acceleration software ensures you'll crush your
              recruiting goals.
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Drive more placements</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Present candidates with video, giving you an edge over
                    competition.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Make recruiters wildly productive.</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Everything your candidates need to hire candidates &amp;
                    make placements
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Manage your candidate pipeline with predictability</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Get insights into your hiring process, and how to
                        improve
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Teams20DataSource = {
  wrapper: {
    className: "home-page-wrapper teams2-wrapper kf5juqefecp-editor_css",
  },
  page: { className: "home-page teams2" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Recruiting Teams Love DeepHire</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "block-wrapper kf5evgs3jor-editor_css",
    gutter: 72,
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://deephire.s3.amazonaws.com/websiteAssets/testimonal1.png",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            {
              name: "title",
              className: "teams2-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Carol Young</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "teams2-job",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Head of Recruiting, Top Placements</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content1",
              className: "teams2-content kf5g3tuey5k-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          DeepHire gives me an unfair advantage vs my
                          competition. I'm able to close more deals and make
                          more placements because of DeepHire.&nbsp;
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://deephire.s3.amazonaws.com/websiteAssets/testimonal2.png",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            {
              name: "title",
              className: "teams2-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Richard Green</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "teams2-job",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Partner, Chase Staffing</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content1",
              className: "teams2-content kf5g4td089r-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      DeepHire has simplified our process across our firm.
                      Recruiters are able to use DeepHire for pre-screening and
                      candidate presentation.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        image: {
          name: "image",
          className: "teams2-image",
          children:
            "https://deephire.s3.amazonaws.com/websiteAssets/testimonial3.png",
        },
        titleWrapper: {
          className: "teams2-textWrapper",
          children: [
            {
              name: "title",
              className: "teams2-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Maria Lee</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "teams2-job",
              children: (
                <span>
                  <p>Director of Recruiting, Virtual Hire</p>
                </span>
              ),
            },
            {
              name: "content1",
              className: "teams2-content kf5g6pngof-editor_css",
              children: (
                <span>
                  <p>
                    Our clients absolutely love DeepHire. The software allows us
                    to share videos of our candidates instead of just their
                    resume.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper kf5gp68241-editor_css",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image kf5gpem6rhe-editor_css",
      },
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <p>Ready To Make Your Recruiters More Effective?</p>
              </span>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <p>
                DeepHire is more than just software. With our Recruiting
                Platform, you get a playbook for team success.
              </p>
            </span>
          </span>
        ),
        className: "title-content",
      },
      {
        name: "content2",
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: "title-content kf5gprx77vi-editor_css",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <span>
              <p>Start Now</p>
            </span>
          </span>
        ),
      },
    },
  },
}
